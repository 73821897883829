import React, {useState} from "react";
import RecordSource from '../Sources/navContents.json';
import siteLogo from '../../Assets/siteLogo.png';
import toggle from '../../Assets/toggle.png';
import exit from '../../Assets/exit.png';
import '../../Styles/Unified/main.min.css';
import {Link} from "react-router-dom";

interface SubRecord {
    id: number;
    text: string;
    link: string;
    external: boolean;
}

interface SubRecords extends Array<SubRecord>{}

interface Record {
    id: number;
    title: string;
    link: string;
    content: SubRecords;
    external: boolean;
}

interface Records extends Array<Record>{}

const Navbar: React.FC = () => {
    const [isClicked, setIsClicked] = useState(true);
    const [style, setStyle] = useState('NavPhone NavPhoneDisplayNone');
    const [button, setButton] = useState(toggle);

    const handleActive = (clicked: boolean) => {
        setIsClicked(!clicked);
        if (isClicked){
            setStyle('NavPhone NavPhoneDisplayFlex')
            setButton(exit)
        }
        else{
            setStyle('NavPhone NavPhoneDisplayNone')
            setButton(toggle)
        }
    }

    const [navLinks] = useState<Records>(
        RecordSource.map( link => { return (
            {
                id: link.id,
                title: link.title,
                link: link.link,
                external: link.external,
                content: link.content.map( subLinks => { return (
                    {
                        id: subLinks.id,
                        text: subLinks.text,
                        link: subLinks.link,
                        external: subLinks.external
                    } as SubRecord
                )})
            } as Record
        )})
    )

    return(
        <nav className={'Navbar'}>
            <button className={'NavToggle'} onClick={() => handleActive(isClicked)}>
                <img src={button} alt={'toggle'}/>
            </button>
            <div className={style}>
                {
                    navLinks.map( linkItem => { return (
                        <ul key={ linkItem.id }>
                            <header>
                                {
                                    (linkItem.title !== "") ? (
                                        (linkItem.external) ? (
                                            <a onClick={() => handleActive(false)} href={linkItem.link}>{ linkItem.title }</a>
                                        ) : (
                                            <Link onClick={() => handleActive(false)} to={linkItem.link}>{ linkItem.title }</Link>
                                        )
                                    ) : (
                                        <></>
                                    )
                                }
                            </header>
                            { Object.values(linkItem.content).map( subLink => { return (
                                <li key={subLink.id}>
                                    {
                                        (subLink.text !== "") ? (
                                            (subLink.external) ? (
                                                <a onClick={() => handleActive(false)} href={subLink.link}>{ subLink.text }</a>
                                            ) : (
                                                <Link onClick={() => handleActive(false)} to={subLink.link}>{ subLink.text }</Link>
                                            )
                                        ) : (
                                            <></>
                                        )
                                    }
                                </li>
                            )})}
                        </ul>
                    )})
                }
            </div>
            <div className={'NavContents'}>
                <Link className={'NavLogo'} to={'/'}>
                    <img src={siteLogo} alt={'siteLogo'}/>
                </Link>
                {
                    navLinks.map( linkItem => { return (
                        <ul key={ linkItem.id }>
                            <header>
                                {
                                    (linkItem.title !== "") ? (
                                        (linkItem.external) ? (
                                            <a href={linkItem.link}>{ linkItem.title }</a>
                                        ) : (
                                            <Link to={linkItem.link}>{ linkItem.title }</Link>
                                        )
                                    ) : (
                                        <></>
                                    )
                                }
                            </header>
                            { Object.values(linkItem.content).map( subLink => { return (
                                <li key={subLink.id}>
                                    {
                                        (subLink.text !== "") ? (
                                            (subLink.external) ? (
                                                <a href={subLink.link}>{ subLink.text }</a>
                                            ) : (
                                                <Link to={subLink.link}>{ subLink.text }</Link>
                                            )
                                        ) : (
                                            <></>
                                        )
                                    }
                                </li>
                            )})}
                        </ul>
                    )})
                }
            </div>
        </nav>
    )
}

export default Navbar;