import React from "react";
import logo from "../../Assets/logo.svg";

const VTInnovationBuilding: React.FC = () =>{
    return(
        <div className={'Splash'}>
            <img src={logo} className={'Splash-logo'} alt={logo}/>
            <ul>
                <li>
                    VT Innovation Building
                </li>
            </ul>
        </div>
    )
}

export default VTInnovationBuilding;