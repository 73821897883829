import React from "react";
import logo from '../../Assets/logo.svg';
import '../../Styles/Unified/main.min.css';

const KineticMachine: React.FC = () => {
    return(
        <div className={'Splash'}>
            <img src={logo} className={'Splash-logo'} alt={logo}/>
            <ul>
                <li>
                    Kinetic Machine
                </li>
            </ul>
        </div>
    )
}

export default KineticMachine;