import React, {useEffect} from 'react';
import '../../Styles/Unified/main.min.css';
import siteLogo from '../../Assets/siteLogo.png';
import {Link, Route, Routes} from 'react-router-dom';
import Navbar from '../Navbar/Navbar';
import Splash from '../Content/Splash';
import Architecture from '../Content/Architecture';
import YouthActionCenterLiberia from '../Content/Youth-Action-Center-Liberia';
import Design from '../Content/Design';
import VTInnovationBuilding from "../Content/VT-Innovation-Building";
import MixedUseStudentHousing from "../Content/Mixed-Use-Student-Housing";
import HouseInTheTwentyFirstCentury from "../Content/House-In-The-Twenty-First-Century";
import ArchitectureSchoolExtension from "../Content/Architecture-School-Extension";
import KineticMachine from "../Content/Kinetic-Machine";
import DuckPondLibrary from "../Content/Duck-Pond-Library";
import HouseInBlacksburg from "../Content/House-In-Blacksburg";
import Watercolor from "../Content/Watercolor";
import DigitalFlyers from "../Content/Digital-Flyers";
import Artworks from "../Content/Artworks";
import Contact from "../Content/Contact";

const App: React.FC = () => {
    useEffect(() => {
        document.title = "Ingrid Brandares"
    }, [])

    const scrollToTop = () => {
        window.scrollTo({
            top:0,
            behavior: 'smooth'
        });
    };

    return (
        <div className={'App-Wrapper'}>
            <div className={'App'}>
                <Navbar/>
                <div className={'AppContent'} >
                    <Link className={"AppLogoLink"} to={"/"}>
                        <img src={siteLogo} alt={siteLogo}/>
                    </Link>
                    <Routes>
                        <Route path={"/"} element={<Splash/>}/>
                        <Route path={"/architecture"} element={<Architecture/>}/>
                        <Route path={"/youth-action-center-liberia"} element={<YouthActionCenterLiberia/>}/>
                        <Route path={"/vt-innovation-building"} element={<VTInnovationBuilding/>}/>
                        <Route path={"/mixed-use-student-housing"} element={<MixedUseStudentHousing/>}/>
                        <Route path={"/house-in-the-twenty-first-century"} element={<HouseInTheTwentyFirstCentury/>}/>
                        <Route path={"/architecture-school-extension"} element={<ArchitectureSchoolExtension/>}/>
                        <Route path={"/kinetic-machine"} element={<KineticMachine/>}/>
                        <Route path={"/duck-pond-library"} element={<DuckPondLibrary/>}/>
                        <Route path={"/house-in-blacksburg"} element={<HouseInBlacksburg/>}/>

                        <Route path={"/design"} element={<Design/>}/>
                        <Route path={"/watercolor"} element={<Watercolor/>}/>
                        <Route path={"/digital-flyers"} element={<DigitalFlyers/>}/>
                        <Route path={"/artworks"} element={<Artworks/>}/>

                        <Route path={"/contact"} element={<Contact/>}/>
                    </Routes>
                    <div className={"Bottom-Reset"}>
                        <button onClick={scrollToTop}>
                            <span className="arrow"> ↑ </span>
                            <span className="preserve-whitespace">Back to Top</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
