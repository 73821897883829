import React from "react";
import siteLogo from '../../Assets/siteLogo.png';
import '../../Styles/Unified/main.min.css';
import SplashSource from '../Sources/splashContents.json';
import {Link} from "react-router-dom";

const Splash: React.FC = () => {
    return(
        <div className={'Splash'}>
            {
                SplashSource.map( splashItem => { return (
                    <div key={splashItem.id} className={'Splash-item'}>
                        <Link to={splashItem.link}>
                            <div className={'Splash-img'}>
                                <img src={splashItem.primary} alt={siteLogo}
                                     onMouseOver={(e) => (
                                         e.currentTarget.src = splashItem.secondary
                                     )}
                                     onMouseOut={(e) => {
                                         e.currentTarget.src = splashItem.primary
                                     }}
                                />
                            </div>
                            <div className={'Splash-desc'}>
                                <header className={'Splash-header'}>{ splashItem.desc }</header>
                                <h6 className={'Splash-subheader'} >{ splashItem.year }</h6>
                                <h6 className={'Splash-subheader'}>{ splashItem.grade }</h6>
                            </div>
                        </Link>
                    </div>
                )})
            }
        </div>
    )
}

export default Splash;